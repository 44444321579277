export default [
  {
    "published": "2019-05-11T00:00:00.000Z",
    "lastUpdated": "2019-05-12T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "netlify",
      "parcel",
      "react",
      "typescript"
    ],
    "slug": "2019/start-blog",
    "title": "Blog始めました"
  },
  {
    "published": "2019-05-25T00:00:00.000Z",
    "lastUpdated": "2021-06-22T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "typescript"
    ],
    "slug": "2019/type-compatibility-in-typescript",
    "title": "TypeScriptにおける型の互換性（翻訳）"
  },
  {
    "published": "2019-06-19T00:00:00.000Z",
    "lastUpdated": "2021-11-03T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "rails",
      "webpack"
    ],
    "slug": "2019/webpack-on-rails",
    "title": "Webpack on Rails"
  },
  {
    "published": "2019-07-03T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "netlify"
    ],
    "slug": "2019/spa-redirect-settings-on-netlify",
    "title": "SPAのためのリダイレクト設定 on Netlify"
  },
  {
    "published": "2019-10-11T00:00:00.000Z",
    "lastUpdated": "2024-05-22T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "github"
    ],
    "slug": "2019/create-pull-request-on-command-line",
    "title": "コマンドラインでプルリクエストを作る"
  },
  {
    "published": "2020-06-06T00:00:00.000Z",
    "lastUpdated": "2022-04-23T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "github-actions",
      "nodejs",
      "nvm"
    ],
    "slug": "2020/using-nvm-on-github-actions",
    "title": "GitHub Actionsでnvmを使う"
  },
  {
    "published": "2020-09-07T00:00:00.000Z",
    "lastUpdated": "2022-04-23T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2020/rubykaigi-takeout-2020",
    "title": "RubyKaigi Takeout 2020に参加した"
  },
  {
    "published": "2020-09-10T00:00:00.000Z",
    "lastUpdated": "2022-04-23T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2020/rubykaigi-takeout-2020-2",
    "title": "RubyKaigi Takeout 2020に参加した（2）"
  },
  {
    "published": "2020-12-21T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "advent-calendar",
      "rbs",
      "ruby",
      "steep"
    ],
    "slug": "2020/example-of-type-checking-by-steep",
    "title": "Steepによる型チェックの実践例"
  },
  {
    "published": "2021-06-23T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "parcel",
      "webpack"
    ],
    "slug": "2021/parcel-to-webpack",
    "title": "ParcelからWebpackに移行した話"
  },
  {
    "published": "2021-06-30T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "github-actions"
    ],
    "slug": "2021/automate-git-update-via-github-actions",
    "title": "Gitを自動でアップデートするGitHub Actionを書いた"
  },
  {
    "published": "2021-07-08T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "css",
      "css-modules",
      "tailwindcss"
    ],
    "slug": "2021/css-modules-to-tailwindcss",
    "title": "CSS ModulesからTailwind CSSに切り替えた"
  },
  {
    "published": "2021-07-09T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "css",
      "dark-mode",
      "tailwindcss"
    ],
    "slug": "2021/dark-mode",
    "title": "Dark Mode"
  },
  {
    "published": "2021-09-04T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "git"
    ],
    "slug": "2021/git-partial-clone",
    "title": "Git Partial Clone"
  },
  {
    "published": "2021-09-07T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "docker"
    ],
    "slug": "2021/dockerfile-best-practices-changed",
    "title": "Dockerfileのベストプラクティスが変わった話"
  },
  {
    "published": "2021-09-23T00:00:00.000Z",
    "lastUpdated": "2021-09-27T16:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2021/rubykaigi-takeout-2021-1",
    "title": "RubyKaigi Takeout 2021 - #1"
  },
  {
    "published": "2021-09-30T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ecs",
      "github-actions"
    ],
    "slug": "2021/ecs-deploy-via-github-actions",
    "title": "ECS Deploy via GitHub Actions"
  },
  {
    "published": "2021-10-26T00:00:00.000Z",
    "lastUpdated": "2022-09-20T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "kaigionrails",
      "rails",
      "ruby"
    ],
    "slug": "2021/kaigi-on-rails-2021",
    "title": "Kaigi on Rails 2021"
  },
  {
    "published": "2021-11-03T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "percy",
      "test"
    ],
    "slug": "2021/introduce-percy",
    "title": "Introduce Percy"
  },
  {
    "published": "2021-11-05T00:00:00.000Z",
    "lastUpdated": "2022-04-24T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "markdown",
      "rehype",
      "remark",
      "unified",
      "webpack"
    ],
    "slug": "2021/yet-another-remark-loader",
    "title": "Webpack remark-loaderの代わりを作った話"
  },
  {
    "published": "2021-11-23T00:00:00.000Z",
    "lastUpdated": "2024-06-11T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "emacs"
    ],
    "slug": "2021/build-emacs-from-source",
    "title": "Emacsをソースからビルドした"
  },
  {
    "published": "2021-12-03T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "css",
      "stylelint"
    ],
    "slug": "2021/stylelint-v14",
    "title": "Stylelint 14.0.0"
  },
  {
    "published": "2021-12-17T00:00:00.000Z",
    "lastUpdated": "2021-12-17T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "rubocop",
      "ruby"
    ],
    "slug": "2021/how-to-create-custom-rule-for-rubocop",
    "title": "RuboCopカスタムルールを気軽に作る"
  },
  {
    "published": "2022-02-19T00:00:00.000Z",
    "lastUpdated": "2025-01-03T00:00:00.000Z",
    "author": "Masafumi Koba",
    "tags": [
      "babel",
      "fontawesome",
      "react"
    ],
    "slug": "2022/use-babel-macro-for-fontawesome-6",
    "title": "Font Awesome 6でBabelマクロを使う"
  },
  {
    "published": "2022-03-05T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "slug": "2022/change-background-of-homepage",
    "title": "ホームページの背景を変えた",
    "tags": []
  },
  {
    "published": "2022-03-10T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "github-actions",
      "ruby",
      "steep"
    ],
    "slug": "2022/steep-problem-matcher",
    "title": "Steep Problem Matcher"
  },
  {
    "published": "2022-04-21T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "react"
    ],
    "slug": "2022/react-18",
    "title": "React 18"
  },
  {
    "published": "2022-04-23T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "markdown",
      "remark",
      "twitter"
    ],
    "slug": "2022/tweet-into-blog",
    "title": "Tweet into Blog"
  },
  {
    "published": "2022-09-20T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2022/rubykaigi-2022",
    "title": "RubyKaigi 2022"
  },
  {
    "published": "2022-09-24T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "shell",
      "tips"
    ],
    "slug": "2022/highlighting-command-help-via-bat",
    "title": "Highlighting command help via bat(1)"
  },
  {
    "published": "2022-10-08T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "github-actions",
      "ruby"
    ],
    "slug": "2022/release-gem-using-github-actions",
    "title": "GitHub ActionsでRuby gemをリリースする"
  },
  {
    "published": "2022-11-20T00:00:00.000Z",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "font"
    ],
    "slug": "2022/try-fira-code-font",
    "title": "Fira Codeフォントを試す"
  },
  {
    "published": "2023-06-03T00:30:00.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2023/rubykaigi-2023",
    "title": "RubyKaigi 2023"
  },
  {
    "published": "2024-01-22T21:47:32.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "firefox",
      "macos"
    ],
    "slug": "2024/look-up-in-dictionary-in-firefox-on-macos",
    "title": "macOSのFirefoxでLook Up in Dictionary機能を使えるようにする"
  },
  {
    "published": "2024-05-23T00:00:00.000+09:00",
    "lastUpdated": "2024-05-25T00:00:00.000+09:00",
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2024/rubykaigi-2024-1",
    "title": "RubyKaigi 2024 (1)"
  },
  {
    "published": "2024-05-25T00:00:00.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2024/rubykaigi-2024-2",
    "title": "RubyKaigi 2024 (2)"
  },
  {
    "published": "2024-06-12T00:00:00.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2024/rubykaigi-2024-3-lrama-and-prism",
    "title": "RubyKaigi 2024 (3) - Lrama and Prism"
  },
  {
    "published": "2024-06-19T00:00:00.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "ruby",
      "rubykaigi"
    ],
    "slug": "2024/rubykaigi-2024-4-out-of-the-box",
    "title": "RubyKaigi 2024 (4) - Out-of-the-box"
  },
  {
    "published": "2024-06-20T00:00:00.000+09:00",
    "lastUpdated": null,
    "author": "Masafumi Koba",
    "tags": [
      "javascript",
      "prettier",
      "tskaigi"
    ],
    "slug": "2024/thoughts-on-prettiers-future-talk",
    "title": "TSKaigi2024トーク「Prettierの未来を考える」の感想"
  },
  {
    "published": "2024-09-11T02:48:51.438Z",
    "lastUpdated": "2024-09-12T13:59:00+0000",
    "author": "Masafumi Koba",
    "tags": [
      "fukuokark",
      "ruby"
    ],
    "slug": "2024/fukuoka-rubyistkaigi-04",
    "title": "Fukuoka RubyistKaigi 04"
  }
]